<template>
  <div class="vb-quote-view">
    <b-container>

      <section class="section section--loader" v-if="loading">
        <loader size="large" :show_timing_slot="true" />
      </section>

      <section class="section" v-if="!loading">

        <div v-if="Object.keys(quote).length > 0">
          <quote
              :quote="quote"
          />
        </div>
        <div v-else>TODO error</div>

      </section>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import {service_handler} from "@src/services";
import Quote from '@alpitour/components/views/content-blocks/quote.vue'

export default {
  name: 'quote-view',

  props: {
  },

  components: {
    quote: Quote,
  },

  data() {
    return {
      loading: true,
      quote: {}
    }
  },

  created() {
  },

  computed: {
    // global state keys
    ...mapState([
      'searching',
    ]),    
    // module state keys
    ...mapState(
      'liveat', [
        'last_search',
        'current_search',
        'selected_room',
      ]),
  },

  mounted() {
    this.get_quote()
  },

  methods: {

    get_quote() {
      service_handler(
          'quote',
          this.selected_room.quote_payload,
      )
          .then(
              (success) => {
                this.log('[quote.vue] - success')
                this.log(success.data)

                this.quote = success.data.data
                this.loading = false

              },
              (error) => {
                this.log('[quote.vue] - error')
                this.log(error.response)
                this.loading = false
              }
          )

    },

  }

}
</script>
<style lang="scss" scoped>
</style>
