/*export const passenger = {
  nome: 'Nome',
  cognome:'Cognome',
  dataNascita: 'Data di nascita',
  nazionalita: 'Nazionalità',
  countCell: 'Prefisso',
  numCell: 'Cellulare',
  tipPax: 'Sesso',
  visto: 'Visto'
}*/

export const pax_model = {
  default: {
    type: 'text',
    label: '',
    placeholder: '',
    value: '',
    disabled: false,
    tooltip: 'Contattaci per sapere come valorizzare questo campo.',
    hidden: false,
    //value_default: (data) => { return ''; // value},
    validate: {},
    autocomplete: 'chrome-off',
  },
  tipPax: {
    label: 'Passeggero',
    type: 'select',
    options: [
      {value: 'MR', text: 'ADULTO'}, // lato server MR diventa MS se sesso == F
      {value: 'CHD', text: 'BAMBINO'},
      {value: 'INF', text: 'INFANTE'},
    ],
    validate: {alpha_spaces: true, min:2},
    disabled: true,
    tooltip: '',
    hidden: true,
    value_default: (d) => {
      d = d || {}

      if(!d.pax.passengerType) return 'MR'

      //if(d.pax.age <= 1) return 'INF';
      //if(d.pax.age >= 18) return 'MR'; // indifferente uomo/donna
      //return 'CHD';

      switch(d.pax.passengerType){
        case 'ADULT':
          return 'MR';

        case 'CHILD':
          return 'CHD';

        case 'INFANT':
          return 'INF';

        default:
          return 'MR';
      }

    },
  },
  sesso: {
    label: 'Genere',
    type: 'select',
    options: [
      {value: '', text: 'Scegli', disabled: true},
      {value: 'M', text: 'Sig.'},
      {value: 'F', text: 'Sig.ra'},
    ],
    validate: {alpha_spaces: true, min:1},
    tooltip: '',
  },
  nome: {
    label: 'Nome',
    placeholder: 'Inserisci il nome',
    validate: {alpha_spaces: true, min: 2},
    tooltip: '',
    autocomplete: 'on',
  },
  cognome: {
    label: 'Cognome',
    placeholder: 'Inserisci il cognome',
    validate: {alpha_spaces: true, min: 2},
    tooltip: '',
    autocomplete: 'on',
  },
  dataNascita: {
    label: 'Data di nascita',
    placeholder: 'Inserisci la data di nascita',
    type: 'calendar',
    calendar: {
      show_datepicker: false,
      model_config: {
        type: 'string',
        mask: 'DD-MM-YYYY', // Uses 'iso' if missing
      },
      min: '',
      max: '',
    },
    tooltip: '',
  },
  nazionalita: {
    label: 'Nazionalità',
    placeholder: 'Inserisci la nazionalità',
    type: 'select',
    validate: {alpha_spaces: true, min: 3},
    value: 'ITA',
    options: [
      {
        "value": "AFG",
        "text": "Afghanistan"
      },
      {
        "value": "ALB",
        "text": "Albania"
      },
      {
        "value": "DZA",
        "text": "Algeria"
      },
      {
        "value": "AND",
        "text": "Andorra"
      },
      {
        "value": "AGO",
        "text": "Angola"
      },
      {
        "value": "AIA",
        "text": "Anguilla"
      },
      {
        "value": "ATA",
        "text": "Antarctica"
      },
      {
        "value": "ATG",
        "text": "Antigua e Barbuda"
      },
      {
        "value": "SAU",
        "text": "Arabia Saudita"
      },
      {
        "value": "ARG",
        "text": "Argentina"
      },
      {
        "value": "ARM",
        "text": "Armenia"
      },
      {
        "value": "ABW",
        "text": "Aruba"
      },
      {
        "value": "",
        "text": "Ascension Island"
      },
      {
        "value": "AUS",
        "text": "Australia"
      },
      {
        "value": "AUT",
        "text": "Austria"
      },
      {
        "value": "AZE",
        "text": "Azerbaigian"
      },
      {
        "value": "BHS",
        "text": "Bahamas"
      },
      {
        "value": "BHR",
        "text": "Bahrain"
      },
      {
        "value": "BGD",
        "text": "Bangladesh"
      },
      {
        "value": "BRB",
        "text": "Barbados"
      },
      {
        "value": "BEL",
        "text": "Belgio"
      },
      {
        "value": "BLZ",
        "text": "Belize"
      },
      {
        "value": "BEN",
        "text": "Benin"
      },
      {
        "value": "BMU",
        "text": "Bermuda"
      },
      {
        "value": "BTN",
        "text": "Bhutan"
      },
      {
        "value": "BLR",
        "text": "Bielorussia"
      },
      {
        "value": "BOL",
        "text": "Bolivia"
      },
      {
        "value": "BES",
        "text": "Bonaire, Sint Eustatius e Saba"
      },
      {
        "value": "BIH",
        "text": "Bosnia ed Erzegovina"
      },
      {
        "value": "BWA",
        "text": "Botswana"
      },
      {
        "value": "BVT",
        "text": "Bouvet"
      },
      {
        "value": "BRA",
        "text": "Brasile"
      },
      {
        "value": "BRN",
        "text": "Brunei"
      },
      {
        "value": "BGR",
        "text": "Bulgaria"
      },
      {
        "value": "BFA",
        "text": "Burkina Faso"
      },
      {
        "value": "BDI",
        "text": "Burundi"
      },
      {
        "value": "KHM",
        "text": "Cambogia"
      },
      {
        "value": "CMR",
        "text": "Camerun"
      },
      {
        "value": "CAN",
        "text": "Canada"
      },
      {
        "value": "CPV",
        "text": "Capo Verde"
      },
      {
        "value": "CAF",
        "text": "Central African Republic"
      },
      {
        "value": "TCD",
        "text": "Ciad"
      },
      {
        "value": "CHL",
        "text": "Cile"
      },
      {
        "value": "CHN",
        "text": "Cina"
      },
      {
        "value": "CYP",
        "text": "Cipro"
      },
      {
        "value": "VAT",
        "text": "Citt\u00e0 del Vaticano"
      },
      {
        "value": "COL",
        "text": "Colombia"
      },
      {
        "value": "KOR",
        "text": "Corea del Sud"
      },
      {
        "value": "CRI",
        "text": "Costa Rica"
      },
      {
        "value": "CIV",
        "text": "Costa d'Avorio"
      },
      {
        "value": "HRV",
        "text": "Croazia"
      },
      {
        "value": "CUB",
        "text": "Cuba"
      },
      {
        "value": "CUW",
        "text": "Cura\u00e7ao"
      },
      {
        "value": "DNK",
        "text": "Danimarca"
      },
      {
        "value": "DMA",
        "text": "Dominica"
      },
      {
        "value": "ECU",
        "text": "Ecuador"
      },
      {
        "value": "EGY",
        "text": "Egitto"
      },
      {
        "value": "SLV",
        "text": "El Salvador"
      },
      {
        "value": "ARE",
        "text": "Emirati Arabi Uniti"
      },
      {
        "value": "ERI",
        "text": "Eritrea"
      },
      {
        "value": "EST",
        "text": "Estonia"
      },
      {
        "value": "ETH",
        "text": "Etiopia"
      },
      {
        "value": "FJI",
        "text": "Figi"
      },
      {
        "value": "PHL",
        "text": "Filippine"
      },
      {
        "value": "FIN",
        "text": "Finlandia"
      },
      {
        "value": "FRA",
        "text": "Francia"
      },
      {
        "value": "GAB",
        "text": "Gabon"
      },
      {
        "value": "GMB",
        "text": "Gambia"
      },
      {
        "value": "GEO",
        "text": "Georgia"
      },
      {
        "value": "DEU",
        "text": "Germania"
      },
      {
        "value": "GHA",
        "text": "Ghana"
      },
      {
        "value": "JAM",
        "text": "Giamaica"
      },
      {
        "value": "JPN",
        "text": "Giappone"
      },
      {
        "value": "GIB",
        "text": "Gibilterra"
      },
      {
        "value": "DJI",
        "text": "Gibuti"
      },
      {
        "value": "JOR",
        "text": "Giordania"
      },
      {
        "value": "GRC",
        "text": "Grecia"
      },
      {
        "value": "GRD",
        "text": "Grenada"
      },
      {
        "value": "GRL",
        "text": "Groenlandia"
      },
      {
        "value": "GLP",
        "text": "Guadalupa"
      },
      {
        "value": "GUM",
        "text": "Guam"
      },
      {
        "value": "GTM",
        "text": "Guatemala"
      },
      {
        "value": "GGY",
        "text": "Guernsey"
      },
      {
        "value": "GUF",
        "text": "Guiana Francese"
      },
      {
        "value": "GIN",
        "text": "Guinea"
      },
      {
        "value": "GNQ",
        "text": "Guinea Equatoriale"
      },
      {
        "value": "GNB",
        "text": "Guinea-Bissau"
      },
      {
        "value": "GUY",
        "text": "Guyana"
      },
      {
        "value": "HTI",
        "text": "Haiti"
      },
      {
        "value": "HND",
        "text": "Honduras"
      },
      {
        "value": "IND",
        "text": "India"
      },
      {
        "value": "IDN",
        "text": "Indonesia"
      },
      {
        "value": "IRN",
        "text": "Iran"
      },
      {
        "value": "IRQ",
        "text": "Iraq"
      },
      {
        "value": "IRL",
        "text": "Irlanda"
      },
      {
        "value": "ISL",
        "text": "Islanda"
      },
      {
        "value": "IMN",
        "text": "Isle of Man"
      },
      {
        "value": "CXR",
        "text": "Isola di Natale"
      },
      {
        "value": "CYM",
        "text": "Isole Cayman"
      },
      {
        "value": "CCK",
        "text": "Isole Cocos"
      },
      {
        "value": "COM",
        "text": "Isole Comore"
      },
      {
        "value": "COK",
        "text": "Isole Cook"
      },
      {
        "value": "FLK",
        "text": "Isole Falkland"
      },
      {
        "value": "FRO",
        "text": "Isole Faroe"
      },
      {
        "value": "HMD",
        "text": "Isole Heard e McDonald"
      },
      {
        "value": "MHL",
        "text": "Isole Marshall"
      },
      {
        "value": "UMI",
        "text": "Isole Minori Esterne degli Stati Uniti"
      },
      {
        "value": "SLB",
        "text": "Isole Salomone"
      },
      {
        "value": "VIR",
        "text": "Isole Vergini"
      },
      {
        "value": "VGB",
        "text": "Isole Vergini Britanniche"
      },
      {
        "value": "ISR",
        "text": "Israele"
      },
      {
        "value": "ITA",
        "text": "Italia"
      },
      {
        "value": "JEY",
        "text": "Jersey"
      },
      {
        "value": "KAZ",
        "text": "Kazakistan"
      },
      {
        "value": "KEN",
        "text": "Kenya"
      },
      {
        "value": "KGZ",
        "text": "Kirghizistan"
      },
      {
        "value": "KIR",
        "text": "Kiribati"
      },
      {
        "value": "",
        "text": "Kosovo"
      },
      {
        "value": "KWT",
        "text": "Kuwait"
      },
      {
        "value": "REU",
        "text": "La Riunione"
      },
      {
        "value": "LAO",
        "text": "Laos"
      },
      {
        "value": "LSO",
        "text": "Lesotho"
      },
      {
        "value": "LVA",
        "text": "Lettonia"
      },
      {
        "value": "LBN",
        "text": "Libano"
      },
      {
        "value": "LBR",
        "text": "Liberia"
      },
      {
        "value": "LBY",
        "text": "Libia"
      },
      {
        "value": "LIE",
        "text": "Liechtenstein"
      },
      {
        "value": "LTU",
        "text": "Lituania"
      },
      {
        "value": "LUX",
        "text": "Lussemburgo"
      },
      {
        "value": "MKD",
        "text": "Macedonia del Nord"
      },
      {
        "value": "MDG",
        "text": "Madagascar"
      },
      {
        "value": "MWI",
        "text": "Malawi"
      },
      {
        "value": "MDV",
        "text": "Maldive"
      },
      {
        "value": "MYS",
        "text": "Malesia"
      },
      {
        "value": "MLI",
        "text": "Mali"
      },
      {
        "value": "MLT",
        "text": "Malta"
      },
      {
        "value": "MNP",
        "text": "Marianne Settentrionali"
      },
      {
        "value": "MAR",
        "text": "Marocco"
      },
      {
        "value": "MTQ",
        "text": "Martinica"
      },
      {
        "value": "MRT",
        "text": "Mauritania"
      },
      {
        "value": "MUS",
        "text": "Mauritius"
      },
      {
        "value": "MYT",
        "text": "Mayotte"
      },
      {
        "value": "MEX",
        "text": "Messico"
      },
      {
        "value": "MDA",
        "text": "Moldavia"
      },
      {
        "value": "MCO",
        "text": "Monaco"
      },
      {
        "value": "MNG",
        "text": "Mongolia"
      },
      {
        "value": "MNE",
        "text": "Montenegro"
      },
      {
        "value": "MSR",
        "text": "Montserrat"
      },
      {
        "value": "MOZ",
        "text": "Mozambico"
      },
      {
        "value": "MMR",
        "text": "Myanmar"
      },
      {
        "value": "NAM",
        "text": "Namibia"
      },
      {
        "value": "NRU",
        "text": "Nauru"
      },
      {
        "value": "NPL",
        "text": "Nepal"
      },
      {
        "value": "NIC",
        "text": "Nicaragua"
      },
      {
        "value": "NER",
        "text": "Niger"
      },
      {
        "value": "NGA",
        "text": "Nigeria"
      },
      {
        "value": "NIU",
        "text": "Niue"
      },
      {
        "value": "NFK",
        "text": "Norfolk"
      },
      {
        "value": "PRK",
        "text": "North Korea"
      },
      {
        "value": "NOR",
        "text": "Norvegia"
      },
      {
        "value": "NCL",
        "text": "Nuova Caledonia"
      },
      {
        "value": "NZL",
        "text": "Nuova Zelanda"
      },
      {
        "value": "OMN",
        "text": "Oman"
      },
      {
        "value": "NLD",
        "text": "Paesi Bassi"
      },
      {
        "value": "PAK",
        "text": "Pakistan"
      },
      {
        "value": "PLW",
        "text": "Palau"
      },
      {
        "value": "PAN",
        "text": "Panama"
      },
      {
        "value": "PNG",
        "text": "Papua Nuova Guinea"
      },
      {
        "value": "PRY",
        "text": "Paraguay"
      },
      {
        "value": "PER",
        "text": "Per\u00f9"
      },
      {
        "value": "PCN",
        "text": "Pitcairn"
      },
      {
        "value": "PYF",
        "text": "Polinesia Francese"
      },
      {
        "value": "POL",
        "text": "Polonia"
      },
      {
        "value": "PRI",
        "text": "Porto Rico"
      },
      {
        "value": "PRT",
        "text": "Portogallo"
      },
      {
        "value": "QAT",
        "text": "Qatar"
      },
      {
        "value": "HKG",
        "text": "Regione Amministrativa Speciale di Hong Kong"
      },
      {
        "value": "MAC",
        "text": "Regione Amministrativa Speciale di Macao"
      },
      {
        "value": "GBR",
        "text": "Regno Unito"
      },
      {
        "value": "CZE",
        "text": "Repubblica Ceca"
      },
      {
        "value": "COD",
        "text": "Repubblica Democratica del Congo"
      },
      {
        "value": "DOM",
        "text": "Repubblica Dominicana"
      },
      {
        "value": "COG",
        "text": "Repubblica del Congo"
      },
      {
        "value": "ROU",
        "text": "Romania"
      },
      {
        "value": "RWA",
        "text": "Ruanda"
      },
      {
        "value": "RUS",
        "text": "Russia"
      },
      {
        "value": "WSM",
        "text": "Samoa"
      },
      {
        "value": "ASM",
        "text": "Samoa Americane"
      },
      {
        "value": "SMR",
        "text": "San Marino"
      },
      {
        "value": "SEN",
        "text": "Senegal"
      },
      {
        "value": "SRB",
        "text": "Serbia"
      },
      {
        "value": "SYC",
        "text": "Seychelles"
      },
      {
        "value": "SLE",
        "text": "Sierra Leone"
      },
      {
        "value": "SGP",
        "text": "Singapore"
      },
      {
        "value": "SXM",
        "text": "Sint Maarten"
      },
      {
        "value": "SVK",
        "text": "Slovacchia"
      },
      {
        "value": "SVN",
        "text": "Slovenia"
      },
      {
        "value": "SOM",
        "text": "Somalia"
      },
      {
        "value": "SGS",
        "text": "South Georgia & South Sandwich Islands"
      },
      {
        "value": "SSD",
        "text": "South Sudan"
      },
      {
        "value": "ESP",
        "text": "Spagna"
      },
      {
        "value": "LKA",
        "text": "Sri Lanka"
      },
      {
        "value": "BLM",
        "text": "St. Barthelemy"
      },
      {
        "value": "SHN",
        "text": "St. Helena"
      },
      {
        "value": "KNA",
        "text": "St. Kitts e Nevis"
      },
      {
        "value": "LCA",
        "text": "St. Lucia"
      },
      {
        "value": "MAF",
        "text": "St. Martin"
      },
      {
        "value": "SPM",
        "text": "St. Pierre e Miquelon"
      },
      {
        "value": "VCT",
        "text": "St. Vincent e Grenadine"
      },
      {
        "value": "FSM",
        "text": "Stati Federati di Micronesia"
      },
      {
        "value": "USA",
        "text": "Stati Uniti d'America"
      },
      {
        "value": "ZAF",
        "text": "Sudafrica"
      },
      {
        "value": "SDN",
        "text": "Sudan"
      },
      {
        "value": "SUR",
        "text": "Suriname"
      },
      {
        "value": "SJM",
        "text": "Svalbard"
      },
      {
        "value": "SWE",
        "text": "Svezia"
      },
      {
        "value": "CHE",
        "text": "Svizzera"
      },
      {
        "value": "SWZ",
        "text": "Swaziland"
      },
      {
        "value": "SYR",
        "text": "Syria"
      },
      {
        "value": "STP",
        "text": "S\u00e3o Tom\u00e9 e Pr\u00edncipe"
      },
      {
        "value": "THA",
        "text": "Tailandia"
      },
      {
        "value": "TWN",
        "text": "Taiwan"
      },
      {
        "value": "TJK",
        "text": "Tajikistan"
      },
      {
        "value": "",
        "text": "Tanzania"
      },
      {
        "value": "ATF",
        "text": "Territori Francesi Meridionali e Antartici"
      },
      {
        "value": "PSE",
        "text": "Territori Palestinesi"
      },
      {
        "value": "IOT",
        "text": "Territorio Britannico dell'Oceno Indiano"
      },
      {
        "value": "TLS",
        "text": "Timor-Leste"
      },
      {
        "value": "TGO",
        "text": "Togo"
      },
      {
        "value": "TKL",
        "text": "Tokelau"
      },
      {
        "value": "TON",
        "text": "Tonga"
      },
      {
        "value": "TTO",
        "text": "Trinidad e Tobago"
      },
      {
        "value": "TUN",
        "text": "Tunisia"
      },
      {
        "value": "TUR",
        "text": "Turchia"
      },
      {
        "value": "TKM",
        "text": "Turkmenistan"
      },
      {
        "value": "TCA",
        "text": "Turks e Caicos"
      },
      {
        "value": "TUV",
        "text": "Tuvalu"
      },
      {
        "value": "UKR",
        "text": "Ucraina"
      },
      {
        "value": "UGA",
        "text": "Uganda"
      },
      {
        "value": "HUN",
        "text": "Ungheria"
      },
      {
        "value": "URY",
        "text": "Uruguay"
      },
      {
        "value": "UZB",
        "text": "Uzbekistan"
      },
      {
        "value": "VUT",
        "text": "Vanuatu"
      },
      {
        "value": "VEN",
        "text": "Venezuela"
      },
      {
        "value": "VNM",
        "text": "Vietnam"
      },
      {
        "value": "WLF",
        "text": "Wallis e Futuna"
      },
      {
        "value": "ESH",
        "text": "Western Sahara"
      },
      {
        "value": "YEM",
        "text": "Yemen"
      },
      {
        "value": "ZMB",
        "text": "Zambia"
      },
      {
        "value": "ZWE",
        "text": "Zimbabwe"
      },
      {
        "value": "ALA",
        "text": "\u00c5land Islands"
      }
    ],
    tooltip: '',
  },
  countCell: {
    label: 'Prefisso',
    placeholder: 'Inserisci il prefisso di cellulare',
    type: 'select',
    value: '39',
    options: [
      {
        "value": "93",
        "text": "Afghanistan +93"
      },
      {
        "value": "355",
        "text": "Albania +355"
      },
      {
        "value": "213",
        "text": "Algeria +213"
      },
      {
        "value": "376",
        "text": "Andorra +376"
      },
      {
        "value": "244",
        "text": "Angola +244"
      },
      {
        "value": "1264",
        "text": "Anguilla +1264"
      },
      {
        "value": "672",
        "text": "Antarctica +672"
      },
      {
        "value": "1268",
        "text": "Antigua e Barbuda +1268"
      },
      {
        "value": "966",
        "text": "Arabia Saudita +966"
      },
      {
        "value": "54",
        "text": "Argentina +54"
      },
      {
        "value": "374",
        "text": "Armenia +374"
      },
      {
        "value": "297",
        "text": "Aruba +297"
      },
      {
        "value": "247",
        "text": "Ascension Island +247"
      },
      {
        "value": "61",
        "text": "Australia +61"
      },
      {
        "value": "43",
        "text": "Austria +43"
      },
      {
        "value": "994",
        "text": "Azerbaigian +994"
      },
      {
        "value": "1242",
        "text": "Bahamas +1242"
      },
      {
        "value": "973",
        "text": "Bahrain +973"
      },
      {
        "value": "880",
        "text": "Bangladesh +880"
      },
      {
        "value": "1246",
        "text": "Barbados +1246"
      },
      {
        "value": "32",
        "text": "Belgio +32"
      },
      {
        "value": "501",
        "text": "Belize +501"
      },
      {
        "value": "229",
        "text": "Benin +229"
      },
      {
        "value": "1441",
        "text": "Bermuda +1441"
      },
      {
        "value": "975",
        "text": "Bhutan +975"
      },
      {
        "value": "375",
        "text": "Bielorussia +375"
      },
      {
        "value": "591",
        "text": "Bolivia +591"
      },
      {
        "value": "599",
        "text": "Bonaire, Sint Eustatius e Saba +599"
      },
      {
        "value": "387",
        "text": "Bosnia ed Erzegovina +387"
      },
      {
        "value": "267",
        "text": "Botswana +267"
      },
      {
        "value": "0055",
        "text": "Bouvet +0055"
      },
      {
        "value": "55",
        "text": "Brasile +55"
      },
      {
        "value": "673",
        "text": "Brunei +673"
      },
      {
        "value": "359",
        "text": "Bulgaria +359"
      },
      {
        "value": "226",
        "text": "Burkina Faso +226"
      },
      {
        "value": "257",
        "text": "Burundi +257"
      },
      {
        "value": "855",
        "text": "Cambogia +855"
      },
      {
        "value": "237",
        "text": "Camerun +237"
      },
      {
        "value": "1",
        "text": "Canada +1"
      },
      {
        "value": "238",
        "text": "Capo Verde +238"
      },
      {
        "value": "236",
        "text": "Central African Republic +236"
      },
      {
        "value": "235",
        "text": "Ciad +235"
      },
      {
        "value": "56",
        "text": "Cile +56"
      },
      {
        "value": "86",
        "text": "Cina +86"
      },
      {
        "value": "357",
        "text": "Cipro +357"
      },
      {
        "value": "379",
        "text": "Citt\u00e0 del Vaticano +379"
      },
      {
        "value": "57",
        "text": "Colombia +57"
      },
      {
        "value": "82",
        "text": "Corea del Sud +82"
      },
      {
        "value": "506",
        "text": "Costa Rica +506"
      },
      {
        "value": "225",
        "text": "Costa d'Avorio +225"
      },
      {
        "value": "385",
        "text": "Croazia +385"
      },
      {
        "value": "53",
        "text": "Cuba +53"
      },
      {
        "value": "599",
        "text": "Cura\u00e7ao +599"
      },
      {
        "value": "45",
        "text": "Danimarca +45"
      },
      {
        "value": "1767",
        "text": "Dominica +1767"
      },
      {
        "value": "593",
        "text": "Ecuador +593"
      },
      {
        "value": "20",
        "text": "Egitto +20"
      },
      {
        "value": "503",
        "text": "El Salvador +503"
      },
      {
        "value": "971",
        "text": "Emirati Arabi Uniti +971"
      },
      {
        "value": "291",
        "text": "Eritrea +291"
      },
      {
        "value": "372",
        "text": "Estonia +372"
      },
      {
        "value": "251",
        "text": "Etiopia +251"
      },
      {
        "value": "679",
        "text": "Figi +679"
      },
      {
        "value": "63",
        "text": "Filippine +63"
      },
      {
        "value": "358",
        "text": "Finlandia +358"
      },
      {
        "value": "33",
        "text": "Francia +33"
      },
      {
        "value": "241",
        "text": "Gabon +241"
      },
      {
        "value": "220",
        "text": "Gambia +220"
      },
      {
        "value": "995",
        "text": "Georgia +995"
      },
      {
        "value": "49",
        "text": "Germania +49"
      },
      {
        "value": "233",
        "text": "Ghana +233"
      },
      {
        "value": "1876",
        "text": "Giamaica +1876"
      },
      {
        "value": "81",
        "text": "Giappone +81"
      },
      {
        "value": "350",
        "text": "Gibilterra +350"
      },
      {
        "value": "253",
        "text": "Gibuti +253"
      },
      {
        "value": "962",
        "text": "Giordania +962"
      },
      {
        "value": "30",
        "text": "Grecia +30"
      },
      {
        "value": "1473",
        "text": "Grenada +1473"
      },
      {
        "value": "299",
        "text": "Groenlandia +299"
      },
      {
        "value": "590",
        "text": "Guadalupa +590"
      },
      {
        "value": "1671",
        "text": "Guam +1671"
      },
      {
        "value": "502",
        "text": "Guatemala +502"
      },
      {
        "value": "441481",
        "text": "Guernsey +441481"
      },
      {
        "value": "594",
        "text": "Guiana Francese +594"
      },
      {
        "value": "224",
        "text": "Guinea +224"
      },
      {
        "value": "240",
        "text": "Guinea Equatoriale +240"
      },
      {
        "value": "245",
        "text": "Guinea-Bissau +245"
      },
      {
        "value": "592",
        "text": "Guyana +592"
      },
      {
        "value": "509",
        "text": "Haiti +509"
      },
      {
        "value": "504",
        "text": "Honduras +504"
      },
      {
        "value": "91",
        "text": "India +91"
      },
      {
        "value": "62",
        "text": "Indonesia +62"
      },
      {
        "value": "98",
        "text": "Iran +98"
      },
      {
        "value": "964",
        "text": "Iraq +964"
      },
      {
        "value": "353",
        "text": "Irlanda +353"
      },
      {
        "value": "354",
        "text": "Islanda +354"
      },
      {
        "value": "441624",
        "text": "Isle of Man +441624"
      },
      {
        "value": "61",
        "text": "Isola di Natale +61"
      },
      {
        "value": "1345",
        "text": "Isole Cayman +1345"
      },
      {
        "value": "61",
        "text": "Isole Cocos +61"
      },
      {
        "value": "269",
        "text": "Isole Comore +269"
      },
      {
        "value": "682",
        "text": "Isole Cook +682"
      },
      {
        "value": "500",
        "text": "Isole Falkland +500"
      },
      {
        "value": "298",
        "text": "Isole Faroe +298"
      },
      {
        "value": "672",
        "text": "Isole Heard e McDonald +672"
      },
      {
        "value": "692",
        "text": "Isole Marshall +692"
      },
      {
        "value": "246",
        "text": "Isole Minori Esterne degli Stati Uniti +246"
      },
      {
        "value": "677",
        "text": "Isole Salomone +677"
      },
      {
        "value": "1340",
        "text": "Isole Vergini +1340"
      },
      {
        "value": "1284",
        "text": "Isole Vergini Britanniche +1284"
      },
      {
        "value": "972",
        "text": "Israele +972"
      },
      {
        "value": "39",
        "text": "Italia +39"
      },
      {
        "value": "441534",
        "text": "Jersey +441534"
      },
      {
        "value": "7",
        "text": "Kazakistan +7"
      },
      {
        "value": "254",
        "text": "Kenya +254"
      },
      {
        "value": "996",
        "text": "Kirghizistan +996"
      },
      {
        "value": "686",
        "text": "Kiribati +686"
      },
      {
        "value": "383",
        "text": "Kosovo +383"
      },
      {
        "value": "965",
        "text": "Kuwait +965"
      },
      {
        "value": "262",
        "text": "La Riunione +262"
      },
      {
        "value": "856",
        "text": "Laos +856"
      },
      {
        "value": "266",
        "text": "Lesotho +266"
      },
      {
        "value": "371",
        "text": "Lettonia +371"
      },
      {
        "value": "961",
        "text": "Libano +961"
      },
      {
        "value": "231",
        "text": "Liberia +231"
      },
      {
        "value": "218",
        "text": "Libia +218"
      },
      {
        "value": "423",
        "text": "Liechtenstein +423"
      },
      {
        "value": "370",
        "text": "Lituania +370"
      },
      {
        "value": "352",
        "text": "Lussemburgo +352"
      },
      {
        "value": "389",
        "text": "Macedonia del Nord +389"
      },
      {
        "value": "261",
        "text": "Madagascar +261"
      },
      {
        "value": "265",
        "text": "Malawi +265"
      },
      {
        "value": "960",
        "text": "Maldive +960"
      },
      {
        "value": "60",
        "text": "Malesia +60"
      },
      {
        "value": "223",
        "text": "Mali +223"
      },
      {
        "value": "356",
        "text": "Malta +356"
      },
      {
        "value": "1670",
        "text": "Marianne Settentrionali +1670"
      },
      {
        "value": "212",
        "text": "Marocco +212"
      },
      {
        "value": "596",
        "text": "Martinica +596"
      },
      {
        "value": "222",
        "text": "Mauritania +222"
      },
      {
        "value": "230",
        "text": "Mauritius +230"
      },
      {
        "value": "262",
        "text": "Mayotte +262"
      },
      {
        "value": "52",
        "text": "Messico +52"
      },
      {
        "value": "373",
        "text": "Moldavia +373"
      },
      {
        "value": "377",
        "text": "Monaco +377"
      },
      {
        "value": "976",
        "text": "Mongolia +976"
      },
      {
        "value": "382",
        "text": "Montenegro +382"
      },
      {
        "value": "1664",
        "text": "Montserrat +1664"
      },
      {
        "value": "258",
        "text": "Mozambico +258"
      },
      {
        "value": "95",
        "text": "Myanmar +95"
      },
      {
        "value": "264",
        "text": "Namibia +264"
      },
      {
        "value": "674",
        "text": "Nauru +674"
      },
      {
        "value": "977",
        "text": "Nepal +977"
      },
      {
        "value": "505",
        "text": "Nicaragua +505"
      },
      {
        "value": "227",
        "text": "Niger +227"
      },
      {
        "value": "234",
        "text": "Nigeria +234"
      },
      {
        "value": "683",
        "text": "Niue +683"
      },
      {
        "value": "672",
        "text": "Norfolk +672"
      },
      {
        "value": "850",
        "text": "North Korea +850"
      },
      {
        "value": "47",
        "text": "Norvegia +47"
      },
      {
        "value": "687",
        "text": "Nuova Caledonia +687"
      },
      {
        "value": "64",
        "text": "Nuova Zelanda +64"
      },
      {
        "value": "968",
        "text": "Oman +968"
      },
      {
        "value": "31",
        "text": "Paesi Bassi +31"
      },
      {
        "value": "92",
        "text": "Pakistan +92"
      },
      {
        "value": "680",
        "text": "Palau +680"
      },
      {
        "value": "507",
        "text": "Panama +507"
      },
      {
        "value": "675",
        "text": "Papua Nuova Guinea +675"
      },
      {
        "value": "595",
        "text": "Paraguay +595"
      },
      {
        "value": "51",
        "text": "Per\u00f9 +51"
      },
      {
        "value": "870",
        "text": "Pitcairn +870"
      },
      {
        "value": "689",
        "text": "Polinesia Francese +689"
      },
      {
        "value": "48",
        "text": "Polonia +48"
      },
      {
        "value": "1787",
        "text": "Porto Rico +1787"
      },
      {
        "value": "351",
        "text": "Portogallo +351"
      },
      {
        "value": "974",
        "text": "Qatar +974"
      },
      {
        "value": "852",
        "text": "Regione Amministrativa Speciale di Hong Kong +852"
      },
      {
        "value": "853",
        "text": "Regione Amministrativa Speciale di Macao +853"
      },
      {
        "value": "44",
        "text": "Regno Unito +44"
      },
      {
        "value": "420",
        "text": "Repubblica Ceca +420"
      },
      {
        "value": "243",
        "text": "Repubblica Democratica del Congo +243"
      },
      {
        "value": "1809",
        "text": "Repubblica Dominicana +1809"
      },
      {
        "value": "242",
        "text": "Repubblica del Congo +242"
      },
      {
        "value": "40",
        "text": "Romania +40"
      },
      {
        "value": "250",
        "text": "Ruanda +250"
      },
      {
        "value": "7",
        "text": "Russia +7"
      },
      {
        "value": "685",
        "text": "Samoa +685"
      },
      {
        "value": "1684",
        "text": "Samoa Americane +1684"
      },
      {
        "value": "378",
        "text": "San Marino +378"
      },
      {
        "value": "221",
        "text": "Senegal +221"
      },
      {
        "value": "381",
        "text": "Serbia +381"
      },
      {
        "value": "248",
        "text": "Seychelles +248"
      },
      {
        "value": "232",
        "text": "Sierra Leone +232"
      },
      {
        "value": "65",
        "text": "Singapore +65"
      },
      {
        "value": "599",
        "text": "Sint Maarten +599"
      },
      {
        "value": "421",
        "text": "Slovacchia +421"
      },
      {
        "value": "386",
        "text": "Slovenia +386"
      },
      {
        "value": "252",
        "text": "Somalia +252"
      },
      {
        "value": "500",
        "text": "South Georgia & South Sandwich Islands +500"
      },
      {
        "value": "211",
        "text": "South Sudan +211"
      },
      {
        "value": "34",
        "text": "Spagna +34"
      },
      {
        "value": "94",
        "text": "Sri Lanka +94"
      },
      {
        "value": "590",
        "text": "St. Barthelemy +590"
      },
      {
        "value": "290",
        "text": "St. Helena +290"
      },
      {
        "value": "1869",
        "text": "St. Kitts e Nevis +1869"
      },
      {
        "value": "1758",
        "text": "St. Lucia +1758"
      },
      {
        "value": "590",
        "text": "St. Martin +590"
      },
      {
        "value": "508",
        "text": "St. Pierre e Miquelon +508"
      },
      {
        "value": "1784",
        "text": "St. Vincent e Grenadine +1784"
      },
      {
        "value": "691",
        "text": "Stati Federati di Micronesia +691"
      },
      {
        "value": "1",
        "text": "Stati Uniti d'America +1"
      },
      {
        "value": "27",
        "text": "Sudafrica +27"
      },
      {
        "value": "249",
        "text": "Sudan +249"
      },
      {
        "value": "597",
        "text": "Suriname +597"
      },
      {
        "value": "47",
        "text": "Svalbard +47"
      },
      {
        "value": "46",
        "text": "Svezia +46"
      },
      {
        "value": "41",
        "text": "Svizzera +41"
      },
      {
        "value": "268",
        "text": "Swaziland +268"
      },
      {
        "value": "963",
        "text": "Syria +963"
      },
      {
        "value": "239",
        "text": "S\u00e3o Tom\u00e9 e Pr\u00edncipe +239"
      },
      {
        "value": "66",
        "text": "Tailandia +66"
      },
      {
        "value": "886",
        "text": "Taiwan +886"
      },
      {
        "value": "992",
        "text": "Tajikistan +992"
      },
      {
        "value": "255",
        "text": "Tanzania +255"
      },
      {
        "value": "262",
        "text": "Territori Francesi Meridionali e Antartici +262"
      },
      {
        "value": "970",
        "text": "Territori Palestinesi +970"
      },
      {
        "value": "246",
        "text": "Territorio Britannico dell'Oceno Indiano +246"
      },
      {
        "value": "670",
        "text": "Timor-Leste +670"
      },
      {
        "value": "228",
        "text": "Togo +228"
      },
      {
        "value": "690",
        "text": "Tokelau +690"
      },
      {
        "value": "676",
        "text": "Tonga +676"
      },
      {
        "value": "1868",
        "text": "Trinidad e Tobago +1868"
      },
      {
        "value": "216",
        "text": "Tunisia +216"
      },
      {
        "value": "90",
        "text": "Turchia +90"
      },
      {
        "value": "993",
        "text": "Turkmenistan +993"
      },
      {
        "value": "1649",
        "text": "Turks e Caicos +1649"
      },
      {
        "value": "688",
        "text": "Tuvalu +688"
      },
      {
        "value": "380",
        "text": "Ucraina +380"
      },
      {
        "value": "256",
        "text": "Uganda +256"
      },
      {
        "value": "36",
        "text": "Ungheria +36"
      },
      {
        "value": "598",
        "text": "Uruguay +598"
      },
      {
        "value": "998",
        "text": "Uzbekistan +998"
      },
      {
        "value": "678",
        "text": "Vanuatu +678"
      },
      {
        "value": "58",
        "text": "Venezuela +58"
      },
      {
        "value": "84",
        "text": "Vietnam +84"
      },
      {
        "value": "681",
        "text": "Wallis e Futuna +681"
      },
      {
        "value": "212",
        "text": "Western Sahara +212"
      },
      {
        "value": "967",
        "text": "Yemen +967"
      },
      {
        "value": "260",
        "text": "Zambia +260"
      },
      {
        "value": "263",
        "text": "Zimbabwe +263"
      },
      {
        "value": "35818",
        "text": "\u00c5land Islands +35818"
      }
    ],
    tooltip: '',
  },
  prefCell: {
    label: ' ',
    placeholder: '',
    type: 'input',
    disabled: true,
    hidden: true,
    tooltip: '',
  },
  numCell: {
    label: 'Cellulare',
    placeholder: 'Inserisci il numero di cellulare',
    validate: {numeric: true, min:6},
    tooltip: '',
    autocomplete: 'tel',
  },
  visto: {
    label: ' ',
    placeholder: 'Sono a conoscenza che la destinazione richiede visto turistico',
    type: 'checkbox',
    validate: {is: [true]},
    value: true,
    hidden: true,
    tooltip: '',
  },
  consenso: {
    label: ' ',
    placeholder: 'Acconsento al trattamento dei miei dati',
    type: 'checkbox',
    validate: {is: [true]},
    value: true,
    hidden: true,
    tooltip: '',
  },
  email: {
    label: 'Email',
    placeholder: 'La tua email',
    validate: {email: true},
    tooltip: '',
    autocomplete: 'email',
  },
  cap: {
    label: 'Cap',
    placeholder: 'Inserisci il cap',
    tooltip: '',
  },
  citta: {
    label: 'Città',
    placeholder: 'Inserisci la città',
    tooltip: '',
  },
  codComune: {
    label: 'Comune',
    placeholder: 'Inserisci il comune',
    tooltip: '',
  },
  codFisc: {
    label: 'Codice Fiscale',
    placeholder: 'Inserisci il codice fiscale',
    tooltip: '',
  },
  indirizzo: {
    label: 'Indirizzo',
    placeholder: 'Inserisci l\'indirizzo',
    tooltip: '',
  },
  provincia: {
    label: 'Provincia',
    placeholder: 'Inserisci la provincia',
    tooltip: '',
  },
  contatti1: {
    label: 'Contatti tipo 1',
  },
  contatti2: {
    label: 'Contatti tipo 2',
  },
  contatti3: {
    label: 'Contatti tipo 3',
  },
  prefSms: {
    label: 'Prefisso per SMS',
  },
  contatti4: {
    label: 'Contatti tipo 4',
  },
  contatti5: {
    label: 'Contatti tipo 5',
  },
  statoEsteroResid: {
    label: 'Residenza estera',
  },
  comuneNascitaCF: {
    label: 'Comune di nascita',
  },
  provNascitaCF: {
    label: 'Provincia di nascita',
  },
  statoEsteroNasc: {
    label: 'Stato estero nascita',
  },
  numProgPax: {
    hidden: true,
    value_default: (d) => {
      d = d || {}

      if(!d.pax.id) return '0'
      return d.pax.id

    },
  }
}